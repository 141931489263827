import{
    createStore
} from "vuex";

export default createStore({
    state:{
        userInfo:{}
    },
    mutations:{
        setUserInfo(state,userInfo) {
            state.userInfo = userInfo
        }
    },
    getters:{

    },
    actions:{

    },
    modules:{}
})