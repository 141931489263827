import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store";

import "amfe-flexible/index.js";
import 'vant/lib/index.css'
import {
    Button,
    Collapse,
    CollapseItem,
    Tab,
    Tabs,
    Toast,
    Icon
} from 'vant';

console.log(process.env.VUE_APP_API)

const app = createApp(App)

app.use(router)
    .use(store)

app.use(Button)
app.use(Collapse)
app.use(CollapseItem)
app.use(Tab)
app.use(Tabs)
app.use(Toast)
app.use(Icon)

app.mount('#app')
