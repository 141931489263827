import {
    createRouter,
    createWebHashHistory
} from "vue-router";

const routes = [{
    path : '/',
    redirect:"/Home"
},{
    path:'/Home',
    name:"Home",
    component:()=>import('../views/Home'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/Wechat',
    name:"Wechat",
    component:()=>import('../views/Wechat'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/ShopProxy',
    name:"ShopProxy",
    component:()=>import('../views/ShopProxy'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/Proxy',
    name:"Proxy",
    component:()=>import('../views/Proxy'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/ShopProtocol',
    name:"ShopProtocol",
    component:()=>import('../views/ShopProtocol'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/Protocol',
    name:"Protocol",
    component:()=>import('../views/Protocol'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/Role',
    name:"Role",
    component:()=>import('../views/Role'),
    meta:{
        title: '早点淘使用规则'
    }
},{
    path:'/ShopNotice',
    name:"ShopNotice",
    component:()=>import('../views/ShopNotice.vue'),
    meta:{
        title: '通知中心'
    }
},{
    path:'/ShopBanner',
    name:"ShopBanner",
    component:()=>import('../views/ShopBanner.vue'),
    meta:{
        title: '早点淘'
    }
},{
    path:'/Invite',
    name:"Invite",
    component:()=>import('../views/Invite'),
    meta:{
        title: '邀请新人赢好礼规则'
    }
},{
    path:'/Recharge',
    name:"Recharge",
    component:()=>import('../views/Recharge'),
    meta:{
        title: '充值活动返现规则'
    }
},{
    path:'/Pickerrule',
    name:"Pickerrule",
    component:()=>import('../views/Pickerrule'),
    meta:{
        title: '红包规则'
    }
},{
    path:'/VipCard',
    name:"VipCard",
    component:()=>import('../views/VipCard'),
    meta:{
        title: 'VIP会员规则活动政策'
    }
},{
    path:'/Zhuli',
    name:"Zhuli",
    component:()=>import('../views/Zhuli'),
    meta:{
        title: '邀请好友助力规则'
    }
},{
    path:'/Coupon',
    name:"Coupon",
    component:()=>import('../views/Coupon'),
    meta:{
        title: '优惠券使用说明'
    }
},
{
    path:'/CouponPackage',
    name:"CouponPackage",
    component:()=>import('../views/CouponPackage'),
    meta:{
        title: '活动规则页'
    }
},
{
    path:'/PickupInstructions',
    name: "PickupInstructions",
    component:()=>import('../views/PickupInstructions'),
    meta:{
        title: '取件说明'
    }
},
{
    path:'/StorageInstructions',
    name:"StorageInstructions",
    component:()=>import('../views/StorageInstructions'),
    meta:{
        title: '存件说明'
    }
}, 
{
    path:'/DownloadApp',
    name:"DownloadApp",
    component:()=>import('../views/DownloadApp'),
    meta:{
        title: '早点淘APP'
    }
},
{
    path:'/ActivityOne',
    name:"ActivityOne",
    component:()=>import('../views/ActivityOne'),
    meta:{
        title: '霸王餐活动规则'
    }
}
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router