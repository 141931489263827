<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
  import router from './router'
  router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })

</script>

<style>

</style>
